.loader {
  position: relative;
  overflow: inherit;
}

.is-loading {
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 30px 0;
  color: @theme-grey-5;
}

.loader.overlay {
  &.is-loading,
  .is-loading {
    padding: 10% 25px;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 999;
    cursor: pointer;
    display: table;
    text-align: center;
    font-size: 25px;
    color: #caccce;
    span {
      vertical-align: middle;
      display: table-cell;
    }
  }
}

/* Animations */
.fade-loader.ng-enter,
.fade-loader.ng-leave {
  transition: all linear 125ms;
}
.fade-loader.ng-enter,
.fade-loader.ng-leave.ng-leave-active {
  opacity: 0;
}
.fade-loader.ng-leave,
.fade-loader.ng-enter.ng-enter-active {
  z-index: 1;
  opacity: 1;
}
.fade-loader.ng-leave {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
}
