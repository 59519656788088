@media (min-width: @screen-sm-min) {
  .dropdown {
    .dropdown-menu {
      transform: scale(0.75);
      transition: all 0.125s ease-in;
      transform-origin: top center;
      display: block;
      opacity: 0;
      visibility: hidden;
      padding: 0;
      > li {
        a {
          padding: 10px 20px;
        }
      }
    }
    &.open {
      .dropdown-menu {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
