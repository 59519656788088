.panel {
  &.panel-default {
    &.panel-empty {
      border: none;
    }

    > .panel-heading {
      min-height: 50px;

      > .panel-heading-buttons {
        background-color: @background-color;

        .btn-group.btn-group-dropdown {
          box-shadow: none;

          &:hover {
            box-shadow: none;
          }

          &.open .dropdown-toggle {
            box-shadow: none;
            background: #fff;

            :hover {
              background: darken(@background-color, 5%);
            }
          }
        }

        .dropdown-toggle {
          border: none;
          padding: 0;
          margin: 0;

          :focus {
            outline: 0;
          }

          > button {
            padding: 14px 10px 13px 10px;
          }
        }

        .dropdown-menu {
          box-shadow: none;

          a {
            width: 100%;
          }

          a:hover {
            background: darken(@background-color, 5%);
          }
        }
      }
    }

    &.panel-customer {
      border-color: var(--colorEnvBaseBg, @defaultColorEnvBaseBg);

      & > .panel-heading {
        color: #fff;
        background-color: var(--colorEnvBaseBg, @defaultColorEnvBaseBg);
      }
    }
  }
}

.panel {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  &.panel-default {
    border-color: @panel-border-color;
    > .panel-heading {
      background: white;
      border-color: @panel-border-color;
      position: relative;
      left: 0;
      right: 0;
      /* MAGIC HAPPENS HERE */
      transform: translateZ(0);
      -moz-transform: translatez(0);
      -ms-transform: translatez(0);
      -o-transform: translatez(0);
      -webkit-transform: translateZ(0);
      -webkit-transform: translate3d(0, 0, 0);
      -webkit-font-smoothing: antialiased;
      /* seems to do the same in Safari */
      z-index: 15;

      @media (min-width: @screen-sm-min) {
        transition: all 0.25s ease-in;
      }

      &.panel-heading-fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1030;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.125);
      }

      &.panel-without-z-index {
        transform: inherit !important;
        -moz-transform: inherit !important;
        -ms-transform: inherit !important;
        -o-transform: inherit !important;
        -webkit-transform: inherit !important;
        -webkit-transform: inherit !important;
        z-index: 0 !important;
      }
    }
  }

  &.panel--secondary {
    margin: 0;

    > .panel-heading {
      background-color: #f5f5f5;
    }
  }

  .panel-heading {
    padding: 15px;
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0;
    }
    h1 {
      font-size: @fontSizeLG;
      overflow: hidden;
      .btn {
        vertical-align: top;
      }
    }
    .panel-heading-buttons {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      background-color: #fff;
      .form-control {
        width: auto;
      }
      .popover {
        min-width: 200px;
      }
      .toggle-input {
        float: left;
        padding: 12px;
      }
      .search-input {
        display: inline;
        @media (max-width: @screen-xs-max) {
          > input {
            pointer-events: none;
            -webkit-transition: all 0.25s ease-in;
            transition: all 0.25s ease-in;
            &::-webkit-input-placeholder {
              color: white;
              &:hover,
              &:active {
                color: #f2f2f2;
              }
            }
            &:focus {
              max-width: 100px;
              pointer-events: auto;
              &::-webkit-input-placeholder {
                color: @input-color-placeholder;
              }
              + label {
                opacity: 0 !important;
              }
            }
            + label {
              position: absolute;
              left: 0;
              padding: 13px;
            }
          }
        }
      }
      button,
      a,
      input,
      > .select-box {
        border-radius: 0;
        float: left;
        border-top: none;
        border-bottom: none;
        border-right: none;
        border-left: 1px solid @panel-border-color;
        padding: 6px 12px;
        height: 100%;
        background: none;

        &:hover {
          border-left: 1px solid @panel-border-color;
          background: #f2f2f2;
        }

        @media (max-width: @screen-xs-max) {
          > span {
            display: none;
          }
        }

        > select {
          border: none;
          background: none;
          padding-top: 10px;
          font-size: 16px;
          color: #555555;

          &:active {
            border: none;
          }
        }
      }

      @media (max-width: @screen-xs-max) {
        input {
          max-width: 40px;
          &::before {
            content: "O";
          }
        }
      }
    }
  }
  .panel-footer {
    border-color: @panel-border-color;
    background: lighten(@panel-border-color, 5%);
  }
}

.panel .panel-heading .progress {
  width: 100px;
  position: absolute;
  top: 11px;
  right: 11px;
}

.panel .panel-heading {
  #back-to-top {
    display: none;
    margin-right: 35px;
    > i {
      border-right: 1px solid @panel-border-color;
      position: fixed;
      top: 0;
      left: 0;
      height: 50px;
      margin-top: 0px;
      padding-top: 15px;
      margin-right: 0px;
      width: 40px;
      padding-right: 10px;
      padding-left: 13px;
    }
    &:hover {
      cursor: pointer;
      > i {
        background: #f2f2f2;
      }
    }
  }
}

.panel .panel-heading.panel-heading-fixed {
  #back-to-top {
    display: inherit;
  }
}

.panel-title-evaluation-template {
  font-size: 16px;
}
