.pinned-popover {
  max-width: 350px;
  width: auto;
  position: fixed;
  bottom: 10px;
  right: 60px;
  left: inherit !important;
  top: auto !important;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.125);
  .popover-content {
    .list-group {
      margin: -9px -14px 10px -14px; // min margin to even popover padding
      max-height: 250px;
      overflow: auto;
      a {
        border-left: none;
        border-right: none;
        opacity: 1;
        overflow: hidden;
        position: relative;

        &:first-of-type {
          border-top: 0;
        }

        &.ng-enter,
        &.ng-leave {
          -webkit-transition: 0.125s cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
          -moz-transition: 0.125s cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
          -ms-transition: 0.125s cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
          -o-transition: 0.125s cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
          transition: 0.125s cubic-bezier(0.25, 0.25, 0.75, 0.75) all;
          position: relative;
          display: block;
        }

        &.ng-leave.ng-leave-active,
        &.ng-enter {
          -webkit-transition: 0.25s;
          transition: 0.25s;
          opacity: 0;
          line-height: 0;
          padding-top: 0;
          padding-bottom: 0;
          > .badge {
            overflow: hidden;
            padding: 0;
          }
        }

        &.ng-enter.ng-enter-active,
        &.ng-leave {
          opacity: 1;
          line-height: 1;
          > .badge {
            height: 0;
            padding: 0;
          }
        }
      }
    }
  }
}
#pinned {
  position: fixed;
  margin: 0;
  padding: 0;
  z-index: 9999;
  right: 10px;
  bottom: 10px;
  max-height: 100%;

  > .btn {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.125);
    margin: 5px 0 0 5px;
    &:hover {
      background-color: lighten(@theme-blue-gray, 5%);
    }
  }
}

.export-not-allowed {
  color: darkgrey !important;
}
