.balloon {
  position: relative;
  display: inline-block;
}

.balloon .balloontext {
  visibility: visible;
  background-color: @brand-primary;
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  margin-top: 5px;

  position: absolute;
  z-index: 1;
}
.balloon .balloontext {
  top: -5px;
  right: 105%;
}

.balloon .balloontext::after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent @brand-primary;
}

.panel .panel-heading .panel-heading-buttons {
  .balloon .balloontext {
    margin-top: 15px;
    margin-right: 3px;
  }
}
