.timer pre {
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 35px;
  background-color: #d9edf7;
  border: 1px solid #bce8f1;
  color: black;
  font-family: digital, arial, verdana;
  border-radius: 5px;
  a {
    color: black;
    text-decoration: none;
  }
}

.timer {
  h2 {
    color: #000;
  }
  .progress {
    height: 40px;
    .progress-bar {
      padding-top: 10px;
      font-size: 30px;
    }
  }
}
