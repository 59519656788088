/* TimeEdit Typography */
.font-face-declaration(@fontStyle, @fontWeight, @fontFileName) {
  @font-face {
    font-family: "Source Sans 3";
    src: url("../fonts/@{fontFileName}.ttf") format("truetype");
    font-weight: @fontWeight;
    font-style: @fontStyle;
  }
}

.font-face-declaration(normal, normal, "SourceSans3-Regular");
.font-face-declaration(italic, normal, "SourceSans3-Italic");
.font-face-declaration(normal, 600, "SourceSans3-SemiBold");
.font-face-declaration(italic, 600, "SourceSans3-SemiBoldItalic");

@fontFamily: "Source Sans 3", "Helvetica Neue", Helvetica, Arial, sans-serif;

@fontSizeNormal: 15px;
@fontSizeSM: 0.86rem;
@fontSizeLG: 1.14rem;
@fontSizeXL: 1.43rem;
@fontSizeIcon: 0.86rem;

@fontSizeHeading1: 2.71rem;
@fontSizeHeading2: 2.14rem;
@fontSizeHeading3: 1.71rem;
@fontSizeHeading4: 1.43rem;
@fontSizeHeading5: 1.14rem;

@fontWeightItalic: 400;
@fontWeightNormal: 400;
@fontWeightStrong: 600;

@lineHeightAddition: 0.57rem;
@lineHeightNormal: calc(@fontSizeNormal + @lineHeightAddition);
@lineHeightSM: calc(@fontSizeSM + @lineHeightAddition);
@lineHeightLG: calc(@fontSizeLG + @lineHeightAddition);
@lineHeightXL: calc(@fontSizeXL + @lineHeightAddition);

.font-mixin(@weight, @lineHeight, @fontSize, @style: normal, @decoration: none) {
  font-family: @fontFamily;
  font-weight: @weight;
  font-style: @style;
  line-height: @lineHeight;
  font-size: @fontSize;
  text-decoration: @decoration;
}

.font-base-normal {
  .font-mixin(@fontWeightNormal, @lineHeightNormal, @fontSizeNormal);
}

.font-base-strong {
  .font-mixin(@fontWeightStrong, @lineHeightNormal, @fontSizeNormal);
}

.font-base-underline {
  .font-mixin(@fontWeightNormal, @lineHeightNormal, @fontSizeNormal, normal, underline);
}

.font-base-delete {
  .font-mixin(@fontWeightNormal, @lineHeightNormal, @fontSizeNormal, normal, line-through);
}

.font-base-italic {
  .font-mixin(@fontWeightItalic, @lineHeightNormal, @fontSizeNormal, italic);
}

.font-sm-normal {
  .font-mixin(@fontWeightNormal, @lineHeightSM, @fontSizeSM);
}

.font-sm-strong {
  .font-mixin(@fontWeightStrong, @lineHeightSM, @fontSizeSM);
}

.font-sm-underline {
  .font-mixin(@fontWeightNormal, @lineHeightSM, @fontSizeSM, normal, underline);
}

.font-sm-delete {
  .font-mixin(@fontWeightNormal, @lineHeightSM, @fontSizeSM, normal, line-through);
}

.font-sm-italic {
  .font-mixin(@fontWeightItalic, @lineHeightSM, @fontSizeSM, italic);
}

.font-lg-normal {
  .font-mixin(@fontWeightNormal, @lineHeightLG, @fontSizeLG);
}

.font-lg-strong {
  .font-mixin(@fontWeightStrong, @lineHeightLG, @fontSizeLG);
}

.font-lg-underline {
  .font-mixin(@fontWeightNormal, @lineHeightLG, @fontSizeLG, normal, underline);
}

.font-lg-delete {
  .font-mixin(@fontWeightNormal, @lineHeightLG, @fontSizeLG, normal, line-through);
}

.font-lg-italic {
  .font-mixin(@fontWeightItalic, @lineHeightLG, @fontSizeLG, italic);
}

.font-xl-normal {
  .font-mixin(@fontWeightNormal, @lineHeightXL, @fontSizeXL);
}

.font-xl-strong {
  .font-mixin(@fontWeightStrong, @lineHeightXL, @fontSizeXL);
}

.font-xl-underline {
  .font-mixin(@fontWeightNormal, @lineHeightXL, @fontSizeXL, normal, underline);
}

.font-xl-delete {
  .font-mixin(@fontWeightNormal, @lineHeightXL, @fontSizeXL, normal, line-through);
}

.font-xl-italic {
  .font-mixin(@fontWeightItalic, @lineHeightXL, @fontSizeXL, italic);
}

.font-heading-1 {
  .font-mixin(@fontWeightStrong, @fontSizeHeading1 + @lineHeightAddition, @fontSizeHeading1);
}

.font-heading-2 {
  .font-mixin(@fontWeightStrong, @fontSizeHeading2 + @lineHeightAddition, @fontSizeHeading2);
}

.font-heading-3 {
  .font-mixin(@fontWeightStrong, @fontSizeHeading3 + @lineHeightAddition, @fontSizeHeading3);
}

.font-heading-4 {
  .font-mixin(@fontWeightStrong, @fontSizeHeading4 + @lineHeightAddition, @fontSizeHeading4);
}

.font-heading-5 {
  .font-mixin(@fontWeightStrong, @fontSizeHeading5 + @lineHeightAddition, @fontSizeHeading5);
}

/* Base Typography */
html,
body {
  font-size: @fontSizeNormal;
}

h1,
h2,
h3,
h4,
h5,
.text-bold {
  font-weight: @fontWeightStrong;
}

.text-small {
  font-size: @fontSizeSM;
}

.text--capitalize-first-letter::first-letter {
  text-transform: uppercase;
}

/* Bootstrap Overrides */
@font-family-sans-serif: @fontFamily;
@font-size-base: @fontSizeNormal;
@font-size-h1: @fontSizeHeading1;
@font-size-h2: @fontSizeHeading2;
@font-size-h3: @fontSizeHeading3;
@font-size-h4: @fontSizeHeading4;
@font-size-h5: @fontSizeHeading5;
