.panel-footer {
  .pagination {
    padding: 0px;
    margin: 2px 0px 0px 0px;
    > .active {
      > a {
        z-index: 0;
      }
    }
  }
}

.search {
  font-weight: @fontWeightNormal;
  font-size: 15px;
}
