.cropArea {
  overflow: hidden;
  width: 100%;
  height: 265px;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);

  &.my-drop-zone {
    border: dotted 3px lightgray;
  }

  &.dragover {
    border: dotted 3px green;
  }
}

.image-preview {
  position: relative;

  img {
    width: 125px;
    height: 125px;
    background-color: #f3f3f3;
    -webkit-box-shadow: 3px 3px 3px 0 #e3e3e3;
    -moz-box-shadow: 3px 3px 3px 0 #e3e3e3;
    box-shadow: 3px 3px 3px 0 #e3e3e3;
    border: 1px solid #c3c3c3;
    margin: 6px 0 0 6px;
  }

  span i {
    color: red;
    font-size: 1.5em;
    padding: 10px 0 0 5px;
    vertical-align: top;
    cursor: pointer;
  }
}
