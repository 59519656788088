.nav {
  &.nav-pills.mocked-te-theme {
    li {
      border: none;
      .user-select--none;

      a, &.active ~ .active a /* ensure only one marked active */ {
        display: flex;
        align-items: center;
        color: @colorTextBase;
        background-color: inherit;

        .nav--sidebar--item--label::before {
          display: none;
        }

        &:hover,
        &:active,
        &:focus {
          background-color: #ededed;
        }
      }

      &.active {
        a {
          color: #007644;
          background-color: #e1fff2;

          .nav--sidebar--item--label::before {
            content: "\00a0";
            display: inline-block;
            position: relative;
            width: 3px;
            height: 20px;
            background-color: #007644;
            border-radius: 1px;
            margin-right: 8px;
          }
        }
      }
    }
  }

  &.nav-tabs {
    li {
      border: none;

      a {
        border: none;
        border-bottom: 3px solid transparent;
        margin: 0;
        transition: 0.125s all ease-in;

        &:hover,
        &:active,
        &:focus {
          border: none;
          background: lighten(@brand-primary, 45%);
          border-bottom: 3px solid @brand-primary;
        }
      }

      &.active {
        a {
          border-bottom: 3px solid @brand-primary;
        }
      }
    }
  }
}

.navbar.navbar-inverse {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.125);
  background: #3498db;
  margin-bottom: 0;
  border-radius: 0;

  .nav.navbar-nav {
    > li {
      > a {
        padding: 15px 10px;

        &.dropdown-toggle {
          > img {
            max-height: 20px;
          }
        }
      }
    }
  }
}

nav.nav--sidebar--sticky {
  position: sticky;
  top: 60px;
  max-height: calc(100vh - 60px - 25px);
  overflow-y: auto;
}
