/* Main marketing message and sign up button */
.jumbotron {
  text-align: center;
  border-bottom: 1px solid @border-color;
}
.jumbotron .btn {
  font-size: 15px;
  padding: 14px 24px;
}
.jumbotron.small {
  margin-bottom: 0px;
  padding: 30px;
  > h1 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 40px;
  }
  p {
    font-size: 20px;
    font-weight: @fontWeightNormal;
    margin-left: 10%;
    margin-right: 10%;
  }
}

/* Responsive: Portrait tablets and up */
@media screen and (min-width: 768px) {
  /* Remove the bottom border on the jumbotron for visual effect */
  .jumbotron {
    border-bottom: 0;
  }
}
