.member-photo {
  ul {
    padding-top: 7px;
    margin-right: 10px;
    float: left;
    font-size: 13px;
  }

  img {
    border: 1px solid #eee;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
    width: 51px;
  }
}
