/* Space out content a bit */
body {
  padding-top: @padding;
  padding-bottom: @padding;
  background-color: @background-color;
  &.blank {
    background-color: white;
  }
}

/* Everything but the jumbotron gets side spacing for mobile first views */
.header,
.marketing,
.footer {
  padding-left: 15px;
  padding-right: 15px;
}

/* Custom page header */
.header {
  border-bottom: 1px solid darken(@border-color, 50%);
}
/* Make the masthead heading the same height as the navigation */
.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
  padding-bottom: 15px;
}

/* Custom page footer */
.footer {
  padding-top: 15px;
  color: #777;
  border-top: 1px solid @border-color;
}

/* Customize container */
@media (min-width: 768px) {
  .container {
    max-width: 1030px;
  }
}
.container-narrow > hr {
  margin: 30px 0;
}

.credits {
  font-size: 90%;
  color: #3e4855;
  text-align: center;
  a {
    color: #3e4855;
    &:hover,
    &:visited,
    &:active,
    &:link {
      text-decoration: none;
    }
  }
}

.footer .dropdown-menu {
  border: none;
  background-color: black;
  color: white;
  font-size: 12px;
  margin-bottom: 0px;
}
