.person-popover-anchor {
  display: inline-block;
  + .popover {
    padding-bottom: 0;
    border-color: @theme-blue-gray;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.125);
    > .arrow {
      border-top-color: @theme-blue-gray;
      &::after {
        border-top-color: lighten(@theme-blue-gray, 5%);
      }
    }
    .popover-inner {
      .popover-content {
        padding-bottom: 0;
      }
    }
  }
}

.person-avatar {
  .popover {
    padding: 0 1px;
    .popover-content {
      padding-bottom: 0;
    }
  }
  a:hover {
    text-decoration: none;
  }
}

.person-popover {
  > img {
    width: 175px;
    height: 175px;
    text-align: center;
    border-radius: 50%;
    display: block;
    margin: 0 30px 0 auto;
    max-width: 175px;
    max-height: 175px;
    margin-top: -88px;
    border: 10px solid white;
    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.125);
  }
  a:hover {
    text-decoration: none;
  }
  a,
  h4 {
    display: block;
    text-align: center;
  }
  h4 {
    margin: 20px 0 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 244px;
  }
  .person-popover-footer {
    background: lighten(@theme-blue-gray, 5%);
    border-top: 1px solid @theme-blue-gray;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    span,
    a {
      font-size: 1rem;
      color: @theme-grey-3;
      text-align: center;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      i {
        display: block;
        padding-bottom: 10px;
        font-size: @fontSizeIcon;
      }
    }
  }
}

.popover {
  .popover-inner {
    .popover-content {
      .filter-popover {
        a:hover {
          text-decoration: none;
        }
        select.form-control,
        input[type="text"].form-control {
          width: 100%;
          height: 36px;
          border: 1px solid @theme-blue-gray;
        }
        p > strong {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        label {
          float: left;
          padding-right: 10px;
          margin-bottom: 10px;
        }
        button,
        a {
          margin-bottom: 10px;
        }
        .clear > button {
          border: none;
        }
        .date-label,
        .input-group {
          width: 100%;
        }

        .uib-datepicker-popup {
          left: -100px !important;
        }
      }
    }
  }
}

.uib-datepicker-popup.dropdown-menu {
  min-width: 0;
  z-index: 1100;
}

.popover {
  .popover-inner {
    .popover-content {
      .create-evaluation-popover {
        a:hover {
          text-decoration: none;
        }
        button {
          border: 1px solid @brand-primary;
          margin-bottom: 10px;
          width: 100%;
        }
        select.form-control {
          width: 100%;
          border: 1px solid darkgrey;
          height: 35px;
          margin-bottom: 10px;
        }
        .input-group-addon {
          border: 1px solid darkgrey;
        }
      }
    }
  }

  &.popover-md {
    width: 330px;
    max-width: min(330px, 100%);
    margin-left: 5px;
    margin-right: 5px;
  }
}

.popover {
  .popover-inner {
    .popover-content {
      .option-template-import-popover {
        button {
          border: 1px solid @theme-grey-5;
          margin-bottom: 10px;
          width: 100%;
        }
        select.form-control,
        input[type="text"].form-control {
          width: 100%;
          height: 36px;
          border: 1px solid @theme-blue-gray;
        }
      }
    }
  }
}

.debug-info-popover {
  dl {
    margin: 0;

    dt,
    dd {
      display: inline;
    }

    dt::after {
      content: ":";
    }
  }
}
