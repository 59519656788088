@breadcrumb-light-bg: lighten(@theme-blue-gray, 5%);
.breadcrumb {
  background: @breadcrumb-light-bg;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-bottom: 15px;
  > li.last {
    background: darken(@breadcrumb-light-bg, 2%);
    color: darken(@breadcrumb-light-bg, 25%);
    &::after {
      border-left: 12px solid darken(@breadcrumb-light-bg, 2%);
    }
    &:hover {
      cursor: default;
    }
  }
  > li {
    padding-left: 15px;
    position: relative;
    line-height: 38px;
    + li {
      padding-left: 20px;
      &::before,
      &::after {
        content: " ";
      }
    }
    &::before,
    &::after {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      position: absolute;
      top: 50%;
      margin-top: -25px;
      left: 100%;
    }
    &::before {
      border-left: 12px solid white;
      margin-left: 1px;
      z-index: 1;
    }
    &::after {
      border-left: 12px solid @breadcrumb-light-bg;
      z-index: 2;
    }
    &:last-of-type {
      background: darken(@breadcrumb-light-bg, 2%);
      color: darken(@breadcrumb-light-bg, 25%);
      &::after {
        border-left: 12px solid darken(@breadcrumb-light-bg, 2%);
      }
      &:hover {
        cursor: default;
      }
    }
  }
}

.breadcrumb.no-space {
  margin-bottom: 0px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  li {
    min-width: 50px; // Firefox
    flex-basis: auto;
    > span {
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  li:first-of-type {
    min-width: 33px;
  }
  li:last-of-type {
    min-width: auto;
    max-width: 50%;
  }
  li:nth-child(n + 2) {
    display: none;
  }

  /* === For phones =================================== */
  @media (max-width: 767px) {
    li:nth-last-child(-n + 3) {
      display: block;
    }
  }

  /* === For tablets ================================== */
  @media (min-width: 768px) and (max-width: 991px) {
    li:nth-last-child(-n + 5) {
      display: block;
    }
  }

  /* === For desktops ================================== */
  @media (min-width: 992px) {
    li:nth-last-child(-n + 8) {
      display: block;
    }
  }
}
