.dashboard {
  > .panel-body {
    padding: 0px 25px 2px 15px;
    background-color: #fbfbfb;
  }
  .widget-row {
    padding: 0px;
    .widget {
      margin: 10px 0px 10px 10px;
      .panel {
        margin: 0px;
        &.panel-default .panel-heading {
          &:hover {
            background-color: #f1f5f9;
          }
        }
        .panel-body {
          padding: 10px;
        }
        .panel-overflow {
          max-height: 200px;
          overflow-y: auto;
        }
        .panel-footer {
          background-color: #fff;
          font-size: 14px;
          text-align: right;
          a:hover {
            text-decoration: none;
          }
        }
      }
    }
    [dnd-list] li {
      padding: 0px;
      border: none;
      &.dndPlaceholder {
        margin: 10px 0px 0px 10px;
      }
    }
  }
}
