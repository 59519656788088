:root {
  // Bars, lines and points
  --chartColorPrimary: var(--colorEnvBaseBg, @defaultColorEnvBaseBg);

  // Space under line-charts
  --chartColorPrimaryTransparent: color-mix(
    in srgb,
    var(--chartColorPrimary) 10%,
    transparent
  );
}
