language-select {
  &:not(.navbar language-select) {
    position: absolute;
    top: 27px;
    right: 0;
    z-index: 1050;

    --colorActiveLanguageItem: var(--colorEnvBaseBg, @defaultColorEnvBaseBg);
  }

  .language-item {
    opacity: 0.6;
    padding: 0px 5px;

    &.active {
      opacity: 1;
    }
  }
}

dice-evaluation-participate {
  language-select {
    right: 0 !important;
  }
}
