[ui-sref],
[ng-click],
.clickable {
  cursor: pointer;
}

.no-clickable {
  cursor: default !important;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.row-eq-height .full {
  height: 100%;
}

.tab-content > .active {
  margin-top: 10px;
}

hr.dotted {
  border: none;
  border-top: 1px dotted @border-color;
  height: 1px;
  width: 100%;
}

.d-f {
  display: flex;
}

.d-if {
  display: inline-flex;
}

:is(.d-f, .d-if) {
  &.align-items-center {
    align-items: center;
  }

  &.align-items-stretch {
    align-items: stretch;
  }

  &.jc-space-between {
    justify-content: space-between;
  }
}
