enum-select + required-label,
element-select + required-label,
notification-template-select + required-label,
member-type-select + required-label,
status-type-select + required-label,
evaluation-type-select + required-label,
date-input + required-label,
select + required-label,
.select-box + required-label,
input + required-label,
textarea + required-label {
  position: absolute;
  z-index: 10;
  top: 7.5px;
}

enum-select + required-label,
element-select + required-label,
notification-template-select + required-label,
member-type-select + required-label,
status-type-select + required-label,
evaluation-type-select + required-label,
select + required-label,
.select-box + required-label {
  right: 40px;
}

date-input.ng-invalid input,
date-input.ng-invalid .input-group-addon,
input.show-error,
input.ng-dirty.ng-invalid {
  border-color: #c00000;
}

ng-quill-editor.error .ql-container {
  border: 1px dashed red;
}

.ql-container.ql-disabled {
  border: 1px solid #dfe8f1;
  background-color: #eee;
}

input[type="number"] + required-label {
  right: 40px;
}

date-input + required-label {
  right: 70px;
}

.input-group {
  input + required-label,
  textarea + required-label {
    right: 10px;
  }
}

input + required-label,
textarea + required-label {
  right: 25px;
}

.select-box {
  position: relative;
  border-radius: 0;
  overflow: hidden;
  &,
  > * {
    cursor: pointer;
  }

  select {
    padding-right: 20px;

    .user-select--none;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-width: 1px !important;
  }

  &::after {
    content: "";
    width: 0;
    position: absolute;
    right: 7px;
    top: 50%;
    margin-top: 3px;
    transform: translate(-50%, -50%);
    border-width: 6px 4px;
    border-style: solid;
    pointer-events: none;
    border-color: darken(@theme-blue-gray, 10%) transparent transparent
      transparent;
    z-index: 10;
  }
}

.user-select--none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

//unrelated styles
.form-error {
  padding: 5px;
  color: @brand-danger;

  > h3 {
    font-size: 1.8rem;
    font-weight: @fontWeightStrong;
  }

  > ul,
  ol {
    padding-top: 5px;
    margin-left: 1.4rem;
  }

  > li {
    padding-bottom: 5px;
    &:last-of-type {
      padding-bottom: 0;
    }
  }
}

.error-fields {
  color: white;
  border: 1px solid @brand-danger;
  border-radius: 0;
  list-style: none;
  list-style-type: none;
  padding: 10px;
  margin: 0;
  background: @brand-danger;
  > li {
    padding-bottom: 5px;
    &:last-of-type {
      padding-bottom: 0;
    }
  }
}

.form-control {
  border-color: @theme-blue-gray;
  box-shadow: none;
  &:active,
  &:focus {
    box-shadow: none;
    border-color: darken(@theme-blue-gray, 15%);
  }
}

.form-horizontal {
  select.form-control {
    display: inline-block;
    padding: 4px 8px;
  }
}

.form-container {
  padding: 2px 4px;
}

.has-success,
.has-warning,
.has-error {
  .form-control {
    box-shadow: none;
    border-color: @theme-blue-gray;
    &:active,
    &:focus {
      box-shadow: none;
    }
  }
}

.has-success {
  .form-control {
    color: @brand-success;
    &:active,
    &:focus {
      border-color: @brand-success;
    }
  }
  .form-control-feedback {
    color: @brand-success;
  }
}

.has-warning {
  .form-control {
    color: @brand-warning;
    &:active,
    &:focus {
      border-color: @brand-warning;
    }
  }
  .form-control-feedback {
    color: @brand-warning;
  }
}

.has-error {
  .form-control {
    color: @brand-danger;
    &:active,
    &:focus {
      border-color: @brand-danger;
    }
  }
  .form-control-feedback {
    color: @brand-danger;
  }
}

.input-group {
  .form-control {
    border-right-width: 0;
    &:active,
    &:focus {
      border: 1px solid @theme-grey-7;

      + .input-group-addon {
        border-color: darken(@theme-blue-gray, 15%);
      }
    }
  }
  .input-group-addon {
    border-color: @theme-blue-gray;
    background: none;
    .transition(~"border-color ease-in-out .15s, box-shadow ease-in-out .15s");
    + .form-control {
      border-right-width: 1px;
    }
  }
  .input-group-container {
    border: 1px solid @theme-grey-7;
    min-height: 29px;
    padding: 5px 10px;
  }
}

.ta-readonly,
.input-group .input-group-addon.readonly {
  background: #eee;
}

@switch-radius: 20px;
@switch-width: (@switch-radius * 2);
@switch-height: @switch-radius;
@switch-transition: all 0.4s;
@switch-padding: 2px;
@switch-ball-padding: (@switch-padding * 4);
@switch-ball-width: (@switch-height - @switch-ball-padding);
@switch-ball-radius: @switch-ball-width;

.toggle-container {
  width: 45px;
}

input[type="radio"],
input[type="checkbox"] {
  accent-color: var(--colorPrimary, @defaultColorPrimary);
}

input[type="checkbox"].toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
  content: "";
  + label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: none;
    width: @switch-width;
    padding: @switch-padding;
    height: @switch-radius;
    background-color: @theme-grey-3;
    vertical-align: middle;
    .user-select(none);
    .rounded(@switch-radius);
    .transition(@switch-transition);
    &:before,
    &:after {
      display: block;
      position: absolute;
      content: "";
    }
    &:before {
      top: @switch-padding;
      left: @switch-padding;
      bottom: @switch-padding;
      right: @switch-padding;
      background-color: @theme-grey-3;
      .rounded(@switch-radius);
      .transition(@switch-transition);
      content: "\f00c";
      font-family: "FontAwesome";
      font-size: 10px;
      padding-left: 0;
      color: @theme-grey-3;
      line-height: 15px;
      padding-left: 5px;
    }
    &:after {
      top: 2px;
      left: 2px;
      bottom: 2px;
      width: 16px;
      background-color: white;
      .rounded(@switch-ball-radius);
      .transition(@switch-transition);
    }
  }

  &:checked + label {
    background-color: var(--colorPrimary, @defaultColorPrimary);

    &:after {
      margin-left: @switch-radius;
      background-color: white;
    }

    &:before {
      float: left;
      color: white;
      background-color: var(--colorPrimary, @defaultColorPrimary);
    }
  }

  &:disabled + label {
    cursor: default;
    background-color: lighten(@theme-grey-3, 25%);

    &:before {
      background-color: lighten(@theme-grey-3, 25%);
    }
  }

  &:disabled:checked + label {
    cursor: default;
    background-color: var(--colorPrimary, @defaultColorPrimary);

    &:before {
      background-color: var(--colorPrimary, @defaultColorPrimary);
    }
  }
}

.control-label {
  word-break: break-word;

  + div,
  + span {
    input[type="checkbox"].toggle {
      + label {
        margin: 8px 0 0 0;
      }
    }
  }
}

.control-container {
  margin-top: 7px;
}

.description-html {
  width: 100%;
  .form-control {
    border: 1px solid @theme-grey-5;
  }
  .ta-scroll-window,
  .description-html-editor {
    height: 150px;
    min-height: 150px;
    &.form-control:first-child {
      border-top-left-radius: 0;
      border-bottom-right-radius: 2px;
    }
  }
  .ta-scroll-window > .ta-bind {
    min-height: 150px;
  }
}

.description-html-toolbar {
  background-color: #f9f9f9;
  padding: 5px;
  border-top: 1px solid @input-border;
  border-left: 1px solid @input-border;
  border-right: 1px solid @input-border;

  .notification-placeholder {
    float: left;
    font-size: 22px;
    margin-right: 4px;
    margin-left: 10px;
  }

  .placeholder-insert-help {
    font-size: 18px;
    float: left;
    padding-top: 8px;
    margin: 0px 5px;
  }
}

.matrix-row {
  border-bottom: 1px solid #ddd;

  &.matrix-row-last {
    border-bottom: none;
    margin-bottom: 10px;
  }

  &:hover {
    background: #f7f8ed;
  }
}

.matrix-header {
  border-bottom: 2px solid #ddd;
  padding: 10px 0;
}

.shortcut {
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow:
    0 1px 0 rgba(0, 0, 0, 0.2),
    0 0 0 1px #ccc inset;
  color: #333;
  display: inline-block;
  font-family: @fontFamily;
  font-size: 11px;
  line-height: 1.4;
  margin: 0 0.1em;
  padding: 0.1em 0.6em;
  text-shadow: 0 1px 0 #fff;
  white-space: nowrap;
}

.form-group.chars {
  position: relative;
  .char-left {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
}

read-only-label {
  i {
    color: black;
    padding: 3px;
  }
}

.panel-heading-buttons {
  .search-input {
    float: left;
    height: 100%;

    input:hover {
      background: none !important;
    }

    .input-icon {
      float: right;
      position: relative;
      right: 15px;
      top: 17px;
    }
  }
}

.search-input {
  > i:hover {
    color: @gray;
  }
}

.input-icon {
  right: 27px;
  top: 10px;
  position: absolute;
}

dl.dl-responsive {
  letter-spacing: -0.31em;
  *letter-spacing: normal;
  word-spacing: -0.43em;

  > dt,
  > dd {
    display: inline-block;
    *display: inline;
    zoom: 1;
    letter-spacing: normal;
    word-spacing: normal;
    vertical-align: top;
    padding: 0;
    margin: 0;
  }

  > dt {
    font-weight: @fontWeightStrong;
    width: 48%;
    padding: 0 2% 0.5em 0;
  }

  > dd {
    margin-right: -1px;
    width: 50%;
    padding: 0 0 0.5em 0;
  }
}

.ql-tooltip {
  z-index: 9;
}

span + tooltip-label {
  margin-left: 5px;
}
