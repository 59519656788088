.drop-box-container {
  display: table;
}

.drop-box {
  background: #f8f8f8;
  border: 5px dashed #ddd;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  padding: 25px;
  margin: 10px;
}

.dragover {
  border: 5px dashed blue;
}
