.customer-container {
  display: block;

  &.customer-container-lang-nl .customer-header #customer-logo {
    background-image: var(
      --customerLogoNL,
      var(--customerLogoGlobal, @defaultCustomerLogo)
    );
  }

  .color--env_base_bg {
    color: var(--colorEnvBaseBg, @defaultColorEnvBaseBg);
  }

  .customer-navbar,
  .progress-bar--customer {
    background-color: var(--colorEnvBaseBg, @defaultColorEnvBaseBg);
    border: none;
  }

  .customer-header {
    padding: 20px 15px;
    margin-top: 5px;

    #customer-logo {
      background-image: var(
        --customerLogoEN,
        var(--customerLogoGlobal, @defaultCustomerLogo)
      );
      background-repeat: no-repeat;
      background-size: contain;
      height: 35px;
    }
  }

  .customer-title {
    h1,
    h2 {
      margin: 0;
    }

    h1 {
      font-size: 1.8rem !important;
      font-weight: @fontWeightStrong;
    }

    h2 {
      font-size: 1.3rem !important;
      font-weight: @fontWeightStrong;
    }
  }

  .customer-body {
    language-select {
      right: 15px;
    }
  }

  .panel.panel-customer {
    .progress-bar-success {
      background-color: rgba(176, 195, 74, 0.77);
    }

    .progress-bar-warning {
      background-color: #dc8c47;
    }
  }

  &.customer-container-xl {
    @media (min-width: @screen-md-min) {
      width: 1100px;
      max-width: 90%;
      margin: auto;

      .customer-header {
        margin: 0 15px;
      }
    }

    .customer-body {
      .preview-evaluation {
        .nav--sidebar {
          @media (min-width: @screen-md-min) {
            display: block;
          }
        }

        .navbar-toggle {
          padding: 0;
          margin: 0;

          &[aria-expanded="true"] {
            .fa {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}

dice-evaluation-participate,
.customer-report:not(dice-evaluation-report .customer-report) {
  .customer-header {
    padding: 20px 0 !important;
  }
}
