.modal {
  .modal-dialog {
    .modal-content {
      .modal-header,
      .modal-footer {
        border: none;
      }
    }
  }
}

.lg-modal-window {
  .modal-dialog {
    width: 90%;
  }
  .modal-header,
  .modal-footer {
    border: none;
  }
}

.medium-modal-window {
  .modal-dialog {
    width: 70%;
  }
  .modal-header,
  .modal-footer {
    border: none;
  }
}

.modal-dialog {
  .modal-footer.text-left {
    text-align: left;
  }
}

@media (min-width: @screen-md-min) {
  .modal {
    margin: 25px;
  }

  .modal-dialog {
    &.modal-xl {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;

      .modal-body {
        max-height: calc(100vh - 212px);
        min-height: calc(100vh - 212px);
        overflow-x: hidden;
        overflow-y: auto;
      }
    }

    .modal-content {
      height: 100%;
      min-height: 100%;
      border: 0 none;
    }
  }
}
